import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import logo from '../images/logo2.png'

const Footer = ({handleClickContact, handleClickAbout}) => {

    return (
        <div className='flex flex-row justify-between bg-bg1 '>
            <div className='flex flex-col items-center ml-3 pt-6'>
                <a href='#'>
                  <img src={logo} />
                </a>
                <h2>CVR: 33305303</h2>
            </div>
            <div className='hidden flex-col p-2 md:flex'>
              <h2 className='text-center text-xl border-b-2 border-primary py-4'>Handelsbetingelser</h2>
              <a href='/betingelser' className='hover:transition-all hover:text-red-900 duration-150 ease-in-out pt-2 md:text-xl'>Abonnementsbetingelser</a>
                {/* <a href='/' className='hover:transition-all hover:text-red-900 hover:underline duration-150 ease-in-out'>Hjem</a>
                <button onClick={(e) => handleClickContact(e)} className='hover:transition-all hover:text-red-900 hover:underline duration-150 ease-in-out'>Kontakt</button>
                <button onClick={(e) => handleClickAbout(e)} className='hover:transition-all hover:text-red-900 hover:underline duration-150 ease-in-out'>Om os</button> */}
            </div>
            <div className='flex flex-col w-48 p-2'>
                <h2 className='text-center text-xl border-b-2 border-primary py-4'>Kontakt</h2>
                <a href='tel:+4520493101' className='flex flex-row pt-2 md:text-xl justify-between'><FaPhone />20 49 31 01</a>
                <a href='mailto:fsh@skovslager.dk'className='flex flex-row pb-2 pt-2 md:text-xl justify-between'><FaEnvelope /> fsh@skovslager.dk</a>
            </div>
        </div>
    )
}

export default Footer;