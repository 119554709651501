import React, {useEffect, useState} from "react";


const Slider = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0)
    const [sliderTouched, setSliderTouched] = useState(false)


    useEffect(() => {
        if (!sliderTouched) {
            const handleAuto = setInterval(() => {
                setCurrentImage((currentImage) => (currentImage + 1) % images.length);
            }, 5000);
            return () => clearInterval(handleAuto)
        }
    }, [currentImage]);

    const plusSlides = (num) => {
        let newIndex = (currentImage+num) % images.length
        setSliderTouched(true)
        setCurrentImage(newIndex < 0 ? newIndex+images.length : newIndex)
    }

    return (
        <>
            <div className="relative mx-auto z-10 xl:hidden">
                <img src={images[currentImage]} alt='index images' className='w-full h-5xl object-cover' />
                <button className="prev" onClick={() => plusSlides(-1)}>&#10094;</button>
                <button className="next" onClick={() => plusSlides(1)}>&#10095;</button>
            </div>
            {/* <div className='text-center pt-5'>
                {images.map((image, index) => (
                    <span className={`dot ${index === currentImage ? 'active' : ''}`} onClick={() => { setSliderTouched(true); setCurrentImage(index) }}/>
                ))}
            </div> */}
        </>
    )
}

export default Slider;