// TODO: Create component to show the 3 options for storage rooms: small, medium, and custom

import React from "react";

import lille_rum from '../assets/img/lille_rum.jpeg'
import rum2 from '../assets/img/rum2.jpg'
import erhverv_m_port from '../assets/img/erhverv_m_port.jpeg'

const StorageOptions = ({ title, text }) => {

    const storage = [
      {
        name: "Privat - mindre rum",
        sizeRange: "2,2 - 6",
        pricePerSquareMeter: 100,
        img_path: "lille_rum"
      },
      {
        name: "Privat / Erhverv - større rum",
        sizeRange: "6 - 16",
        pricePerSquareMeter: 90,
        img_path: "rum2"
      },
      {
        name: "Erhverv",
        sizeRange: "9 - 600",
        pricePerSquareMeter: null,
        img_path: "erhverv"
      },
    ]

    return (
        <div className={`flex flex-col w-full p-10 bg-bg1 min-h-96`}> {/*${background ? `md:bg-${background}` : ''}`}> bg-[url('../images/palletlift.jpg')]*/}
            <h2 className={`flex text-5xl justify-center text-center text-primary pb-2`}
            >
                {title}
            </h2>
            <div className='text-gray-900 text-xl md:text-3xl py-2'>
                {text}
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-10'>
            <div className={`flex flex-col bg-lille_rum bg-cover bg-no-repeat bg-center justify-center text-white rounded-2xl p-6`}
                // style={{ backgroundImage: lille_rum}}
                >
                  <div className="w-full h-full flex flex-col justify-center bg-primary bg-opacity-75 rounded-2xl px-6 py-10">
                  <h2 className='text-4xl capitalize text-center'>
                  {storage[0].name}
                  </h2>
                  <br/>
                  <h2 className='text-5xl text-center font-bold pt-4'>
                  
                  {storage[0].sizeRange}
                  </h2>
                  <h2 className='text-5xl text-center font-bold pt-2 pb-4'>
                  m<sup>2</sup>
                  </h2>
                  <br/>
                  { storage[0].pricePerSquareMeter ? <h2 className='text-2xl pb-4'>
                  <span className="font-bold">{storage[0].pricePerSquareMeter} kr/md</span> pr. kvadratmeter
                  </h2> 
                  :
                  <h2 className='text-2xl pb-4'>
                  Pris efter aftale
                  </h2> 
                  }
                  <a href="tel:+4520493101">
                  <button className="bg-bg2 hover:bg-tertiary mt-auto text-black font-bold py-2 px-4 rounded-full">
                    Hør mere 
                  </button>
                  </a>
                  </div>
                  </div>
                  <div className={`flex flex-col bg-rum2 bg-cover bg-no-repeat bg-center justify-center text-white rounded-2xl p-6`}
                // style={{ backgroundImage: lille_rum}}
                >
                  <div className="w-full h-full flex flex-col justify-center bg-primary bg-opacity-75 rounded-2xl px-6 py-10">
                  <h2 className='text-4xl capitalize text-center'>
                  {storage[1].name}
                  </h2>
                  <br/>
                  <h2 className='text-5xl text-center font-bold pt-4'>
                  
                  {storage[1].sizeRange}
                  </h2>
                  <h2 className='text-5xl text-center font-bold pt-2 pb-4'>
                  m<sup>2</sup>
                  </h2>
                  <br/>
                  { storage[1].pricePerSquareMeter ? <h2 className='text-2xl pb-4'>
                  <span className="font-bold">{storage[1].pricePerSquareMeter} kr/md</span> pr. kvadratmeter
                  </h2> 
                  :
                  <h2 className='text-2xl pb-4'>
                  Pris efter aftale
                  </h2> 
                  }
                  <a href="tel:+4520493101">
                  <button className="bg-bg2 hover:bg-tertiary mt-auto text-black font-bold py-2 px-4 rounded-full">
                    Hør mere 
                  </button>
                  </a>
                  </div>
                  </div>
                  <div className={`flex flex-col bg-erhverv bg-cover bg-no-repeat bg-center justify-center text-white rounded-2xl p-6`}
                // style={{ backgroundImage: lille_rum}}
                >
                  <div className="w-full h-full flex flex-col justify-center bg-primary bg-opacity-75 rounded-2xl px-6 py-10">
                  <h2 className='text-4xl capitalize text-center'>
                  {storage[2].name}
                  </h2>
                  <br/>
                  <h2 className='text-5xl text-center font-bold pt-4'>
                  
                  {storage[2].sizeRange}
                  </h2>
                  <h2 className='text-5xl text-center font-bold pt-2 pb-4'>
                  m<sup>2</sup>
                  </h2>
                  <br/>
                  { storage[2].pricePerSquareMeter ? <h2 className='text-2xl pb-4'>
                  <span className="font-bold">{storage[2].pricePerSquareMeter} kr/md</span> pr. kvadratmeter
                  </h2> 
                  :
                  <h2 className='text-2xl pb-4'>
                  Pris efter aftale
                  </h2> 
                  }
                  <a href="tel:+4520493101">
                  <button className="bg-bg2 hover:bg-tertiary mt-auto text-black font-bold py-2 px-4 rounded-full">
                    Hør mere 
                  </button>
                  </a>
                  </div>
                  </div>
              {/* {storage.map(room => {
                const roomname = room.name
                console.log(room.img_path)
                return (<div className={`flex flex-col ${'bg-' + room.img_path} bg-cover bg-no-repeat bg-center justify-center text-white rounded-2xl p-6`}
                // style={{ backgroundImage: lille_rum}}
                key={room.name}>
                  <div className="w-full h-full flex flex-col justify-center bg-primary bg-opacity-75 rounded-2xl px-6 py-10">
                  <h2 className='text-4xl capitalize text-center'>
                  {roomname}
                  </h2>
                  <br/>
                  <h2 className='text-5xl text-center font-bold pt-4'>
                  {room.sizeRange}
                  </h2>
                  <h2 className='text-5xl text-center font-bold pt-2 pb-4'>
                  m<sup>2</sup>
                  </h2>
                  <br/>
                  { room.pricePerSquareMeter ? <h2 className='text-2xl pb-4'>
                  <span className="font-bold">{room.pricePerSquareMeter} kr/md</span> pr. kvadratmeter
                  </h2> 
                  :
                  <h2 className='text-2xl pb-4'>
                  Pris efter aftale
                  </h2> 
                  }
                  <a href="tel:+4520493101">
                  <button className="bg-bg2 hover:bg-tertiary mt-auto text-black font-bold py-2 px-4 rounded-full">
                    Hør mere 
                  </button>
                  </a>
                  </div>
                  </div>)})} */}
            </div>

        </div>
    )
}

export default StorageOptions