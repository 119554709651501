import React from "react";

const TextCol = ({ title, text, titleColor, textColor, background, bgimage }) => {

    return (
        <div className={`flex flex-col w-full justify-center bg-facade xl:w-1/2`}> {/*${background ? `md:bg-${background}` : ''}`}> bg-[url('../images/palletlift.jpg')]*/}
          <div className="w-full h-full flex flex-col justify-center bg-bg2 bg-opacity-50 px-6 py-10 xl:bg-opacity-100 xl:rounded-none xl:p-10">
            <h1 className={`text-3xl font-semibold ${titleColor ? `text-${titleColor}` : 'text-gray-900'}`}
            >
                {title}
            </h1>
            <div className={`${textColor ? `text-${textColor}` : 'text-gray-900'}`}
            >
                {text}
            </div>
        </div>
        </div>
    )
}

export default TextCol